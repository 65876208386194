




































































































.photos_gallery_section {
  padding: 2rem 0;
  .every_img {
    cursor: pointer;
    min-height: 200px;
    max-height: 200px;
  }

  .img {
    min-height: 200px;
    max-height: 200px;
    border-radius: 4px;
  }
}

.preview_image {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img_for_preview {
    display: block;
    max-width: 100%;
  }
}
.all_images_wrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  // .main_loop_on_img{
  //   width: 32%;
  // }
  .every_img {
    max-width: 400px;
    // height: 350px;
    min-height: 200px;
    max-height: 200px;
  }
  .img {
    display: block;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    // height: 100%;
  }
}
.navbar {
  max-height: 100px;
}
.navbar-brand {
  padding: 0px;
  margin: 0px;
}
a {
  color: #ecb0d5;
}
.login-button {
  background: #f1cadd;
  border: none;
}
#footer {
  background-color: #212529;
  color: #f7f7f7;
}
.list li {
  display: flex;
}

ul {
  list-style: none;
}

a {
  color: #ecb0d5;
}
.like {
  font-size: 40px;
}

.name_and_date {
  // text-align: center;
  padding: 10px 0;
  color: #777;
  display: flex;
  justify-content: space-between;
}
